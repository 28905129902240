@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 100 900;
  font-display: optional;
  src: url(/fonts/inter-var-latin.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF, U+FFFD;
}

:root {
  --space: 4px;
  --space-2x: 8px;
  --space-3x: 12px;
  --space-4x: 16px;
  --space-6x: 24px;
  --space-8x: 32px;
  --space-12x: 48px;
  --space-16x: 64px;
  --space-32x: 128px;

  --text-xs: 12px;
  --text-sm: 14px;
  --text-md: 16px;
  --text-lg: 18px;
  --text-xl: 24px;

  --accents-1: #fafafa;
  --accents-2: #eaeaea;
  --accents-3: #999999;
  --accents-4: #888888;
  --accents-5: #666666;
  --accents-6: #444444;
  --accents-7: #333333;
  --accents-8: #111111;

  --gray: #252729;
  --secondary-color: #8a8f98;
  --sidebar: #0e0e10;
  --header-height: 72px;
  --brand: #845ef7;
}

html {
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  height: 100%;
  box-sizing: border-box;
  touch-action: manipulation;
  font-feature-settings: 'case' 1, 'rlig' 1, 'calt' 0;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
*,
*:before,
*:after {
  box-sizing: inherit;
}
body {
  position: relative;
  min-height: 100%;
  height: 100%;
  margin: 0;
  line-height: 1.65;
  font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto',
    'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  font-size: var(--text-md);
  font-weight: 400;
  min-width: 300px;
  direction: ltr;
  font-feature-settings: 'kern';
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  scroll-behavior: smooth;
}
html,
body {
  color: #111;
  background-color: #fff;
}
::selection {
  background-color: #0099ff;
  color: #fff;
}
[role='grid']:focus {
  outline: none;
}
svg {
  text-rendering: optimizeLegibility;
}
h1,
h2,
h3 {
  margin: 0;
}
a {
  text-decoration: none;
  transition: color 0.2s ease;
  color: var(--secondary-color);
}

iframe {
  width: 100%;
  height: 100%;
  border: none;
}

a[role='button'] {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -khtml-user-select: none;
  user-select: none;
}

svg {
  shape-rendering: crispEdges;
}

svg path,
svg circle {
  shape-rendering: geometricprecision;
}
[data-reach-skip-link] {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  width: 1px;
  margin: -1px;
  padding: 0;
  overflow: hidden;
  position: absolute;
}
[data-reach-skip-link]:focus {
  padding: 1rem;
  position: fixed;
  top: 10px;
  left: 10px;
  background: #fff;
  z-index: 1;
  width: auto;
  height: auto;
  clip: auto;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

@keyframes bellshake {
  0% {
    transform: rotate(0);
  }
  5% {
    transform: rotate(11deg);
  }
  10% {
    transform: rotate(-9deg);
  }
  15% {
    transform: rotate(6deg);
  }
  20% {
    transform: rotate(-5deg);
  }
  25% {
    transform: rotate(3deg);
  }
  28% {
    transform: rotate(-2deg);
  }
  31% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(0);
  }
}

.header-menu-btn {
  background: #333;
  bottom: 0;
  height: 70px;
  left: 0;
  margin: auto;
  position: absolute;
  right: 0;
  top: 0;
  width: 70px;
}
.header-menu-btn-icon {
  bottom: 0;
  height: 20px;
  left: 0;
  margin: auto;
  position: absolute;
  right: 0;
  top: 0;
  width: 30px;
}
.header-menu-btn-line {
  backface-visibility: hidden;
  background: #d8d8d8;
  display: inline-block;
  height: 2px;
  left: 0;
  position: absolute;
  transition: transform 0.5s, width 0.5s, opacity 0.5s;
  width: 100%;
}
.header-menu-btn-line:nth-child(1) {
  top: 0;
}
.header-menu-btn-line:nth-child(2) {
  bottom: 0;
  margin: auto;
  top: 0;
}
.header-menu-btn-line:nth-child(3) {
  bottom: 0;
}
.is-menu-opened .header-menu-btn-line:nth-child(1) {
  transform: translateY(9px) rotate(45deg);
}
.is-menu-opened .header-menu-btn-line:nth-child(2) {
  opacity: 0;
  width: 0;
}
.is-menu-opened .header-menu-btn-line:nth-child(3) {
  transform: translateY(-9px) rotate(-45deg);
}

.chakra-modal__overlay, .blur-filter {
  -webkit-backdrop-filter: blur(2px);
  backdrop-filter: blur(2px);
}

/* Make clicks pass-through */
#nprogress {
  pointer-events: none;
}

#nprogress .bar {
  background: #ddd;
  position: fixed;
  z-index: 5000;
  top: 0;
  left: 0;
  width: 100%;
  height: 2px;
}

/* Fancy blur effect */
#nprogress .peg {
  display: block;
  position: absolute;
  right: 0px;
  width: 10px;
  height: 100%;
  box-shadow: 0 0 4px #1b87ff;
  border-radius: 50rem;
  opacity: 1;

  -webkit-transform: rotate(3deg) translate(0px, -4px);
  -ms-transform: rotate(3deg) translate(0px, -4px);
  transform: rotate(3deg) translate(0px, -4px);
}

/* Remove these to get rid of the spinner */
#nprogress .spinner {
  display: block;
  position: fixed;
  z-index: 1031;
  top: 15px;
  right: 15px;
}

#nprogress .spinner-icon {
  width: 18px;
  height: 18px;
  box-sizing: border-box;

  border: solid 2px transparent;
  border-top-color: #1b87ff;
  border-left-color: #1b87ff;
  border-radius: 50%;

  -webkit-animation: nprogress-spinner 400ms linear infinite;
  animation: nprogress-spinner 400ms linear infinite;
}

.nprogress-custom-parent {
  overflow: hidden;
  position: relative;
}

.nprogress-custom-parent #nprogress .spinner,
.nprogress-custom-parent #nprogress .bar {
  position: absolute;
}

@-webkit-keyframes nprogress-spinner {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}
@keyframes nprogress-spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes slideInLeft {
  from {
    transform: translate3d(-100%, 0, 0);
    visibility: visible;
  }

  to {
    transform: translate3d(0, 0, 0);
  }
}

:root {
  --animate-duration: 525ms;
  --animate-delay: 150ms;
  --animate-repeat: 1;
}

.animated {
  animation-duration: var(--animate-duration);
  animation-fill-mode: both;
}

.animated.infinite {
  animation-iteration-count: infinite;
}

.animated.repeat-1 {
  animation-iteration-count: var(--animate-repeat);
}

.animated.repeat-2 {
  animation-iteration-count: calc(var(--animate-repeat) * 2);
}

.animated.repeat-3 {
  animation-iteration-count: calc(var(--animate-repeat) * 3);
}

.animated.delay-1 {
  animation-delay: var(--animate-delay);
}

.animated.delay-2 {
  animation-delay: calc(var(--animate-delay) * 2);
}

.animated.delay-3 {
  animation-delay: calc(var(--animate-delay) * 3);
}

.animated.delay-4 {
  animation-delay: calc(var(--animate-delay) * 4);
}

.animated.delay-5 {
  animation-delay: calc(var(--animate-delay) * 5);
}

.animated.delay-6 {
  animation-delay: calc(var(--animate-delay) * 6);
}

.animated.delay-7 {
  animation-delay: calc(var(--animate-delay) * 7);
}

.animated.delay-8 {
  animation-delay: calc(var(--animate-delay) * 8);
}

.animated.delay-9 {
  animation-delay: calc(var(--animate-delay) * 9);
}

.animated.delay-10 {
  animation-delay: calc(var(--animate-delay) * 10);
}

.animated.faster {
  animation-duration: calc(var(--animate-duration) / 2);
}

.animated.fast {
  animation-duration: calc(var(--animate-duration) * 0.8);
}

.animated.slow {
  animation-duration: calc(var(--animate-duration) * 2);
}

.animated.slower {
  animation-duration: calc(var(--animate-duration) * 3);
}

@media print, (prefers-reduced-motion: reduce) {
  .animated {
    animation-duration: 1ms !important;
    transition-duration: 1ms !important;
    animation-iteration-count: 1 !important;
  }

  .animated[class*='Out'] {
    opacity: 0;
  }
}

.slideInLeft {
  animation-name: slideInLeft;
}

.no-select {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
